export default class FormContact{
    static initFromDom(){

        $body.on("input","form[data-form-contact] [data-is-mandatory='error']",function(e){
            if($(this).val()){
                $(this).attr("data-is-mandatory","");
            }
        });

        $body.on("submit","form[data-form-contact]",function(e){
            e.preventDefault();
            let $form=$(this);
            let $erreur=$(this).find(".error");
            let $success=$(this).find(".success");
            let nom=$form.find("[name='nom']").val();
            let email=$form.find("[name='email']").val();
            let phone=$form.find("[name='phone']").val();
            let message=$form.find("[name='message']").val();

            $erreur.addClass("hidden");
            $success.addClass("hidden");
            $erreur.text("");
            $success.text("");

            //teste les champs obligatoires
            let $mandatory=$form.find("[data-is-mandatory]");
            let mandatoryError=false;
            $mandatory.each(function(){
                if(!$(this).val()){
                    mandatoryError=true;
                    $(this).attr("data-is-mandatory","error");
                }
            });


            if(mandatoryError){
                $erreur.removeClass("hidden");
                $erreur.text("Veuillez saisir tous les champs.");
            }else{
                $success.removeClass("hidden");
                //$success.text(LayoutVars.translations["Veuillez patienter"]);
                $success.text("Veuillez patienter");
                PovApi.action("formContact",
                    {
                        nom:nom,
                        email:email,
                        phone:phone,
                        message:message
                    }
                ).then(
                    function(e){
                        if(e.success){
                            $success.removeClass("hidden");
                            $success.text("Merci. Votre message a été transmis à notre équipe.");
                            let $form = $("form[data-form-contact]");
                            $form.find("[name='nom']").val('');
                            $form.find("[name='email']").val('');
                            $form.find("[name='phone']").val('');
                            $form.find("[name='message']").val('');

                        }else{
                            $success.addClass("hidden");
                            $erreur.removeClass("hidden");
                            $erreur.text(e.errors.join(". "));
                        }
                    }
                ).catch(
                    function(b){
                        console.error("b",b);
                    }
                );
            }


        })
    }
}