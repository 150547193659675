import Slick from "./organisms/Slick.js";
import FormContact from "./components/FormContact";
//import Layout from "npm/docs/src/components/layout";

export default class Site{
    constructor() {
        /**
         *
         * @type {Site}
         */
        let me = this;
        me._initListeners();
        //---------------------go------------------------------------------
        me.resizeStage();
        me.onDomChange();
        Site.navActive();
    }

    /**
     *
     * @private
     */
    _initListeners() {

        let me=this;

        require("./layout/NavMenu");
        NavMenu.__init();
        require("./components/data-zoom-img");
        require("./components/data-is-lang");
        require("./organisms/data-cards-container.js");

        Slick.initFromDom();

        FormContact.initFromDom();

        //ferme le menu quand on change d'url
        $body.on(EVENTS.HISTORY_CHANGE_URL,function(){
            $body.attr("data-page-transition-state","start");
            //stope en attendant que la transition soit finie
            PovHistory.readyToinject=false;
            //dit qu'on est prêt à afficher la page (s'assure qu'on reste au moins une seconde sur l'écran de transition)
            setTimeout(function(){
                PovHistory.readyToinject=true;
            },500);
            NavMenu.close();
        });

        //changement d'url et HTML injecté
        $body.on(EVENTS.HISTORY_CHANGE_URL_LOADED_INJECTED,function(){
            $body.attr("data-page-transition-state","end");
            me.onDomChange();
            //scroll top
            $(window).scrollTop(0);
            Site.navActive();

            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId){
                //hit google analytics
                gtag('config', LayoutVars.googleAnalyticsId, {'page_path': location.pathname});
            }

        });

        STAGE.on(EVENTS.RESIZE,function(){
            me.resizeStage();
        });
        $body.on(Pov.events.DOM_CHANGE,function(){
            me.onDomChange();
        });

        $(document).scroll(function() {

            var y = $(this).scrollTop();
            if (y > $( window ).height()*2/3) {
                $("#nav").addClass("unfold");
            } else {
                $("#nav").removeClass("unfold");
            }
        });


        $body.on('click', '[fold-switch]', function() {

            let target = $(this).attr('fold-switch');
            let $target = $('[fold-id='+target+']');
            let $container = $('[fold-container='+target+']');

            if($target.length){
                if (parseInt($target.css("max-height")) !== 0){
                    $target.css("max-height", 0);
                    $(this).removeClass("invert");
                    if($container.length > 0) {
                        $container.removeAttr("color-negative");
                        $container.removeAttr("is-open");
                    }
                } else {
                    $target.css("max-height", $target.prop('scrollHeight'));
                    $(this).addClass("invert");
                    if($container.length > 0) {
                        $container.attr("color-negative", "true");
                        $container.attr("is-open", "true");
                    }
                }
            }
        });

        $body.on('click', '[categorie-selection]', function(){
           let selection = $(this).attr('categorie-selection');

           $('[surmesure-categorie]').each(function(){
               let firstCat = $(this).attr('surmesure-categorie');
               $(this).removeClass("not-selected");
               if(!firstCat.includes(selection)) $(this).addClass("not-selected");
           });
        });


    }

    /**
     * Selectionne / déselectionne l'item de nav de la page en cours
     */
    static navActive(){
        $("[data-href-uid]").removeClass("active");
        $("[data-href-uid='"+PovHistory.currentPageInfo.uid+"']").addClass("active");
    }

    /**
     * Adapte des élements à l'écran
     */
    resizeStage(){
        //ou pas :)
    }

    /**
     * Initialisations d'objets dom
     */
    onDomChange(){
        Slick.initFromDom();
        if($(".page").length > 0 && $(".page")[0].hasAttribute("section"))
        {
            $("#nav").attr("section", $(".page").attr("section"));
        } else {
            $("#nav").attr("section","");
        }

        /*
        let count = $(".diagonal-box").length;
        $(".diagonal-box").each(function(key){
            $(this).css("z-index", count-key);
        });
        */

        //ou pas :)
    }
}